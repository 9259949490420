export const menuItems = [
    {
        id: 1,
        label: "menu",
        isTitle: true,
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: "/dashboard",
    },
    {
        id: 26,
        label: "Work Permit Request",
        icon: "bx-add-to-queue",
        link: "/permit/request",
    },
    {
        id: 27,
        label: "Work Permit Closing",
        icon: "bx-window-close",
        link: "/permit/closing",
    },
    {
        id: 28,
        label: "Work Permit Tracking",
        icon: "bx-file-find",
        link: "/permit/tracking",
    },
    // {
    //     id: 25,
    //     label: "Config",
    //     icon: "bx-cog",
    //     subItems:[
    //         {
    //             id: 29,
    //             label: "Config Work Permit",
    //             icon: "bxs-user-plus",
    //             link: "/config/work-permit",
    //             parentId: 22,
    //         }
    //     ]
    // },
    {
        id: 22,
        label: "Master",
        role_id: [17],
        icon: "bx-customize",
        subItems: [
            {
                id: 13,
                label: "Work Permit Type",
                icon: "bxs-badge-check",
                link: "/master/sub-perizinan",
                parentId: 22,
            },
            {
                id: 14,
                label: "Work Permit Status",
                icon: "bxs-info-circle",
                link: "/master/status-permohonan",
                parentId: 22,
            },
            // {
            //     id: 19,
            //     label: "Department",
            //     icon: "bxs-badge-check",
            //     link: "/master/department",
            //     parentId: 22,
            // },
            // {
            //     id: 20,
            //     label: "Division",
            //     icon: "bxs-badge-check",
            //     link: "/master/division",
            //     parentId: 22,
            // },
            {
                id: 17,
                label: "Role",
                icon: "bxs-user-detail",
                link: "/master/role",
                parentId: 22,
            },
            {
                id: 18,
                label: "User",
                icon: "bxs-user-plus",
                link: "/master/user",
                parentId: 22,
            },
        ],
    },
];
