<script>
import { authComputed } from "@/state/helpers";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            session: JSON.parse(localStorage.session),
            roleActive: JSON.parse(localStorage.session).role_name,
            permit_request: [],
            permit_closing: [],
            jumlah_notif: 0,
            roleData: JSON.parse(JSON.parse(localStorage.session).role_json),
        };
    },
    mounted() {
        let self = this;

        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/notification", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var res_fix = response.data.data;
                var permit_request = res_fix?.permit_request;
                var permit_closing = res_fix?.permit_closing;
                self.permit_request = permit_request;
                self.permit_closing = permit_closing;
                self.jumlah_notif = permit_closing.length;
                self.permit_request.forEach(function (val) {
                    if (val.is_draft == "false") {
                        self.jumlah_notif++;
                    }
                });
                // self.permit_closing.forEach(function (val) {
                //     if (val.is_draft == 'false') {
                //         self.jumlah_notif++;
                //     }
                // });
                // console.log(response.data.data)
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.e);
            });
    },
    methods: {
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        ubahRole(key) {
            let self = this;
            var role_used = self.roleData[key];
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "auth/change-role",
                    {
                        role_id: role_used?.id,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    console.log(response_data_fix);
                    localStorage.session = JSON.stringify(response_data_fix);
                    localStorage.role_id = response_data_fix.role_id;
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Halaman akan segera diperbarui.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        // didOpen: () => {
                        //     timerInterval = setInterval(() => {
                        //         const content = Swal.getContent();
                        //         if (content) {
                        //             const b = content.querySelector("b");
                        //             if (b) {
                        //                 b.textContent = Swal.getTimerLeft();
                        //             }
                        //         }
                        //     }, 100);
                        // },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then(() => {
                        /* Read more about handling dismissals below */

                        location.reload();
                    });
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        getNamaNotif(notif_used) {
            var status_permit_kategori = notif_used.status_permit_kategori;
            var ret = "Menunggu Persetujuan";
            if (status_permit_kategori == "Draft") {
                ret = "Menunggu Perbaikan";
            }
            return ret;
        },
        getApprove(notif_used) {
            var last_timeline = notif_used?.work_permit_request_approval?.slice(-1)[0];
            var htmls = "Permit Request " + notif_used?.work_permit_no + " menuggu proses persetujuan anda.";
            if (last_timeline.timeline_kategori == "tolak") {
                htmls = "Permit Request " + notif_used?.work_permit_no + " ditolak oleh " + last_timeline?.user?.name + " dengan remark : " + last_timeline?.remarks;
            }
            return htmls;
        },
    },
    computed: {
        ...authComputed,
    },
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box bg-white">
                    <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo_bayer.webp" alt height="40" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo_bayer.webp" alt height="40" />
                        </span>
                    </router-link>

                    <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo_bayer.webp" alt height="40" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo_bayer.webp" alt height="40" />
                        </span>
                    </router-link>
                </div>

                <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item" @click="toggleMenu">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>
            <div class="d-flex">
                <div class="btn-group noti-icon">
                    <button class="btn btn-black dropdown-toggle header-item" type="button"><span class="text-success">Role Active: </span> {{ roleActive }}</button>
                </div>

                <b-dropdown class="noti-icon" menu-class="dropdown-menu-lg dropdown-menu-end" right toggle-class="header-item" variant="black">
                    <template v-slot:button-content>
                        <i class="bx bx-user"></i>
                    </template>
                    <div class="px-lg-2">
                        <div class="row no-gutters">
                            <div class="col-md-12" v-for="(row_data, key_data) in roleData" :key="key_data">
                                <a v-if="row_data.role == roleActive" style="color: black" class="dropdown-icon-item" href="javascript: void(0);" v-on:click="ubahRole(key_data)">
                                    <span>{{ row_data.role }}</span>
                                </a>
                                <a v-else class="dropdown-icon-item" href="javascript: void(0);" v-on:click="ubahRole(key_data)">
                                    <span>{{ row_data.role }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </b-dropdown>

                <b-dropdown menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="header-item noti-icon" variant="black">
                    <template v-slot:button-content>
                        <i class="bx bx-bell bx-tada"></i>
                        <span class="badge bg-danger rounded-pill">{{ jumlah_notif }}</span>
                    </template>

                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">
                                    {{ $t("navbar.dropdown.notification.text") }}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div style="max-height: 230px; overflow: scroll">
                        <div v-for="(notif_val, notif_key) in permit_request" :key="notif_key">
                            <div v-if="notif_val?.is_draft == 'false'">
                                <router-link
                                    :to="{
                                        name: 'work-permit-detail',
                                        params: { permitId: notif_val.id },
                                    }"
                                    v-if="notif_val.status_permit_kategori == 'Proses'"
                                    class="text-reset notification-item">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <h6 class="mt-0 mb-1">{{ getNamaNotif(notif_val) }}</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" v-html="getApprove(notif_val)"></p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link v-else :to="{ name: 'edit-work-permit', params: { permitId: notif_val.id } }" class="text-reset notification-item">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <h6 class="mt-0 mb-1">{{ getNamaNotif(notif_val) }}</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" v-html="getApprove(notif_val)"></p>
                                            </div>
                                        </div></div
                                ></router-link>
                            </div>
                        </div>

                        <router-link
                            v-for="(notif_val, notif_key) in permit_closing"
                            :key="notif_key"
                            :to="{
                                name: 'work-permit-detail',
                                params: { permitId: notif_val.work_permit_request_id },
                            }"
                            class="text-reset notification-item">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h6 class="mt-0 mb-1">Menungu Persetujuan</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">Permit Request {{ notif_val.work_permit_no }} menuggu proses persetujuan anda.</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </b-dropdown>
                <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <!-- <img class="rounded-circle header-profile-user" src="@/assets/images/users/avatar-1.jpg" alt="Header Avatar" /> -->
                        <span class="d-none d-xl-inline-block ms-1">
                            <div>{{ session?.name }}</div>
                        </span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <b-dropdown-item>
                        <router-link :to="{ name: 'profile-user' }" v-slot="{ navigate }" custom>
                            <span @click="navigate" @keypress.enter="navigate">
                                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                                {{ $t("navbar.dropdown.henry.list.profile") }}
                            </span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <router-link :to="{ name: 'logout' }" class="dropdown-item text-danger">
                        <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                        {{ $t("navbar.dropdown.henry.list.logout") }}
                    </router-link>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>
